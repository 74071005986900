export function replaceTournamentInfoPageLinks(domNode) {
  console.log(domNode);
  if (domNode.name === 'a' && domNode.attribs?.href != null && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
    const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
    const text = findText(domNode);
    return (
      <a href={`/${split}`} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  }
}

function findText(startNode) {
  let currentNode = startNode;
  while (currentNode) {
    if (currentNode.type === 'text') {
      return currentNode.data;
    }
    currentNode = currentNode.children[0];
  }
  return '';
}
