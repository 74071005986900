import React from 'react';
import styles from './Cards.module.css';
import SharepointApi from '../../../api/sharepointApi';
import TourApi from '../../../api/tourApi';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { TOUR_NAMES_BY_CODE } from '../../../util/titles';
import { connect } from 'react-redux';
import Loader from '../Loader/Loader';
import { getPlayerId } from '../../../util/generalUtils';
import { doShowProAmFieldLink, getTournamentFieldPath, isCommitted, isTeamEvent } from '../../../util/commitmentUtils';
import { playerIdTourCodeLookup } from '../../../reducers/commitmentInfoReducer';
import { formatTournamentDateRange } from '../../../util/date';
import { fetchSharePointData } from '../../../util/sharepointUtils';
import { replaceTournamentInfoPageLinks } from '../../../util/sharepoint';
import { SHAREPOINT_BASE_URL } from '../../../url/service-url';

const CACHE = {};

class UpcomingEventCard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      contactInformation: null,
      hardcodedPdf: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { tournament, type, contactInfo } = this.props;
    if (CACHE[tournament.tournament_id] !== undefined) {
      if (this._isMounted) {
        if (type === 'agent') {
          this.setState({
            isLoading: false,
            contactInformation: CACHE[tournament.tournament_id].contactInformation,
            round: CACHE[tournament.tournament_id].roundInformation,
          });
        } else {
          this.setState({
            isLoading: false,
            contactInformation: CACHE[tournament.tournament_id].contactInformation,
            teeTime: CACHE[tournament.tournament_id].tournamentInformation.playerTeeTime,
            showCourse: CACHE[tournament.tournament_id].tournamentInformation.showCourse,
            courseName: CACHE[tournament.tournament_id].tournamentInformation.courseName,
            round: CACHE[tournament.tournament_id].tournamentInformation.round,
            currentRound: CACHE[tournament.tournament_id].tournamentInformation.round,
          });
        }
      }
    } else {
      var resolvedPromisesArray = [];
      if (contactInfo !== undefined) {
        var contactInformation;
        resolvedPromisesArray[0] = SharepointApi.getContent(contactInfo).then((res) => {
          if (res !== undefined && res.value !== undefined) {
            let webParts = res.value;
            contactInformation = `<div>`;
            webParts.forEach((part) => {
              if (part.innerHtml) {
                contactInformation += `${part.innerHtml}`;
              }
            });
            contactInformation += `</div>`;
            CACHE[tournament.tournament_id] = {
              ...CACHE[tournament.tournament_id],
              contactInformation: contactInformation,
            };
            if (this._isMounted) {
              this.setState({
                contactInformation: contactInformation,
              });
            }
            return null;
          } else {
            console.log('USING FALLBACK DATA FOR CONTACT INFO');
            fetchSharePointData(`${SHAREPOINT_BASE_URL}${contactInfo}`).then((response) => {
              if (response) {
                CACHE[tournament.tournament_id] = {
                  ...CACHE[tournament.tournament_id],
                  contactInformation: null,
                };
                if (this._isMounted) {
                  this.setState({
                    hardcodedPdf: response.webUrl,
                  });
                }
              } else {
                CACHE[tournament.tournament_id] = {
                  ...CACHE[tournament.tournament_id],
                  contactInformation: null,
                };
                if (this._isMounted) {
                  this.setState({
                    contactInformation: null,
                  });
                }
              }
            });
          }
        });
      }

      if (type === 'agent') {
        resolvedPromisesArray[resolvedPromisesArray.length] = TourApi.getTournamentStatus(tournament.tournament_id).then((res) => {
          if (res !== undefined && res.rounds !== undefined) {
            CACHE[tournament.tournament_id] = {
              ...CACHE[tournament.tournament_id],
              roundInformation: res.rounds[0].current_round,
            };
            if (this._isMounted) {
              this.setState({
                round: res.rounds[0].current_round,
              });
            }
          }
        });
      } else {
        resolvedPromisesArray[resolvedPromisesArray.length] = TourApi.getTournamentDetails(tournament.tournament_id).then((details) => {
          if (details !== undefined) {
            var courses = details[0].courses;
            var showCourse = false;
            var courseName = '';

            if (courses.length > 1) {
              showCourse = true;
            }
            TourApi.getTournamentStatus(tournament.tournament_id).then((status) => {
              if (status !== undefined && status.rounds !== undefined) {
                var round = status.rounds[0].current_round;

                if (round !== undefined) {
                  TourApi.getTournamentTeeTimes(tournament.tournament_id, round).then((teeTimes) => {
                    if (teeTimes !== undefined) {
                      var playerTeeTime;
                      var playerId = this.props.profile.id;
                      teeTimes.forEach((item) => {
                        if (parseInt(item.p_id) === parseInt(playerId)) {
                          if (showCourse) {
                            courses.forEach((course) => {
                              if (course.id === item.course_id) {
                                courseName = course.name;
                              }
                            });
                          }
                          playerTeeTime = item;
                        }
                      });
                      if (playerTeeTime !== null) {
                        CACHE[tournament.tournament_id] = {
                          ...CACHE[tournament.tournament_id],
                          tournamentInformation: {
                            playerTeeTime: playerTeeTime,
                            showCourse: showCourse,
                            courseName: courseName,
                            round: round,
                            currentRound: round,
                          },
                        };
                        this.setState({
                          isLoading: false,
                          teeTime: playerTeeTime,
                          showCourse: showCourse,
                          courseName: courseName,
                          round: round,
                          currentRound: round,
                        });
                      } else {
                        CACHE[tournament.tournament_id] = {
                          ...CACHE[tournament.tournament_id],
                          tournamentInformation: {
                            playerTeeTime: null,
                            showCourse: null,
                            courseName: null,
                            round: round,
                            currentRound: round,
                          },
                        };
                        this.setState({
                          isLoading: false,
                          teeTime: null,
                          round: round,
                          currentRound: round,
                        });
                      }
                    } else {
                      CACHE[tournament.tournament_id] = {
                        ...CACHE[tournament.tournament_id],
                        tournamentInformation: {
                          playerTeeTime: null,
                          showCourse: null,
                          courseName: null,
                          round: round,
                          currentRound: round,
                        },
                      };
                      if (this._isMounted) {
                        this.setState({
                          isLoading: false,
                          teeTime: null,
                          round: round,
                          currentRound: round,
                        });
                      }
                    }
                  });
                } else {
                  CACHE[tournament.tournament_id] = {
                    ...CACHE[tournament.tournament_id],
                    tournamentInformation: {
                      playerTeeTime: null,
                      showCourse: null,
                      courseName: null,
                      round: null,
                      currentRound: null,
                    },
                  };
                  if (this._isMounted) {
                    this.setState({
                      isLoading: false,
                      teeTime: null,
                    });
                  }
                }
              } else {
                CACHE[tournament.tournament_id] = {
                  ...CACHE[tournament.tournament_id],
                  tournamentInformation: {
                    playerTeeTime: null,
                    showCourse: null,
                    courseName: null,
                    round: null,
                    currentRound: null,
                  },
                };
                if (this._isMounted) {
                  this.setState({
                    isLoading: false,
                    teeTime: null,
                  });
                }
              }
            });
          } else {
            CACHE[tournament.tournament_id] = {
              ...CACHE[tournament.tournament_id],
              tournamentInformation: {
                playerTeeTime: null,
                showCourse: null,
                courseName: null,
                round: null,
                currentRound: null,
              },
            };
            if (this._isMounted) {
              this.setState({
                isLoading: false,
              });
            }
          }
        });
      }

      Promise.all(resolvedPromisesArray).then((response) => {
        if (this._isMounted) {
          this.setState({
            isLoading: false,
          });
        }
      });
    }
  }

  render() {
    const { isLoading, contactInformation, round, hardcodedPdf } = this.state;
    const { tournament } = this.props;

    var fullSize = true;
    if (this.props.globalStyles.isMobileSize || this.props.globalStyles.isTabletSize) {
      fullSize = false;
    }

    if (isLoading) {
      return (
        <div
          className={styles.ShadowCard}
          style={{
            padding: fullSize ? '' : '0 1rem',
            width: fullSize ? '45%' : '100%',
            maxWidth: fullSize ? '48%' : '100%',
          }}
        >
          <Loader height={40} width={40} withWrapper wrapperStyle={{ textAlign: 'center', padding: '2rem 0' }} />
        </div>
      );
    } else {
      const { type } = this.props;
      const { teeTime, showCourse, courseName, currentRound } = this.state;
      const showContactInfo = contactInformation !== null && contactInformation !== undefined;

      const scheduleId = tournament.scheduleId;
      const tourCodeLetter = tournament.tournament_id.charAt(0);
      const tourCodeStyle = 'TourCode' + tourCodeLetter;

      let id = tournament.id + '';
      while (id.length < 3) {
        id = '0' + id;
      }
      const tournamentImageSource = `https://res.cloudinary.com/pgatour-prod/d_tournaments:logos:${tourCodeLetter}000.png/tournaments/logos/${tourCodeLetter}${id}.png`;

      const tourTag = <div className={styles['TourTag' + tourCodeLetter]}>{TOUR_NAMES_BY_CODE[tourCodeLetter]}</div>;

      var proAm1 = false;
      var proAm2 = false;
      if (tournament.proAm1 !== null && tournament.proAm1 !== undefined) {
        if (tourCodeLetter === 'S') {
          if (tournament.promAm2 !== null && tournament.proAm2 !== undefined && tournament.proAm2 === '1') {
            proAm2 = true;
            proAm1 = true;
          } else {
            proAm1 = true;
          }
        } else {
          if (tournament.proAm1 === '1') {
            proAm1 = true;
          }
        }
      }

      const proAmTeeTimesLink = `/sites/PGATOURLINKS/SiteAssets/Pro-Am%20Tee%20Times/ProAmTeeTimes-${tourCodeLetter}-${id}-ProAm1.pdf`;
      const proAmTeeTimesLink2 = `/sites/PGATOURLINKS/SiteAssets/Pro-Am%20Tee%20Times/ProAmTeeTimes-${tourCodeLetter}-${id}-ProAm2.pdf`;

      var showTeeTimes = false;
      var showLinks = false;
      if (round !== undefined && round !== null) {
        showLinks = true;
        showTeeTimes = true;
      }
      const weatherReportLink = '/sites/PGATOURLINKS/SiteAssets/Weather%20Forecast/WeatherForecast-' + tourCodeLetter + id + '.pdf';
      const holeLocationsLink = `/holelocations/${tournament.tournament_id}`;
      const fullTeeTimesLink = `/teetimes/${tournament.tournament_id}`;

      const teamEvent = isTeamEvent(tournament.scheduleId);
      const tournamentFieldPath = getTournamentFieldPath(tournament.scheduleId, teamEvent, tourCodeLetter);
      const tournamentInfoLink = '/tournament/' + this.props.tournamentInfo;
      var owgrPointsLinke = 'https://www.owgr.com/this-week-s-events';
      var tournamentProAmFieldLink = '/field/' + scheduleId + '/proam';
      const savedCommitments = this.props.commitmentInfo[playerIdTourCodeLookup(getPlayerId(tourCodeLetter, this.props), tourCodeLetter)];
      const commitmentsList = savedCommitments && savedCommitments.playerCommitmentTournamentList;
      const commitmentsInfo = commitmentsList?.find((t) => t.tournamentID === tournament.tournament_id) || {};
      const showProamFieldLink = doShowProAmFieldLink(commitmentsInfo);
      const showFieldLink = !commitmentsInfo?.fieldCalculating && commitmentsInfo?.fieldApproved && commitmentsInfo?.eligibleForCommitments;
      let sameMonth = true;
      let showPlayerInfo = false;

      if (type === 'player') {
        sameMonth = commitmentsInfo?.sameMonth;
        showPlayerInfo = true;
      } else {
        let startDate = new Date(tournament.start_date);
        let endDate = new Date(tournament.end_date);

        let startMonth = startDate.getMonth();
        let endMonth = endDate.getMonth();

        if (startMonth !== endMonth) {
          sameMonth = false;
        }
      }

      var teeTimeDate;
      var startDate;
      var endDate;
      if (teeTime !== null && teeTime !== undefined) {
        var teeTimeString = teeTime.time + 'Z';
        teeTimeDate = new Date(teeTimeString);
      }

      if (tournament.start_date !== null && tournament.start_date !== undefined) {
        var startDateString = tournament.start_date + 'Z';
        startDate = new Date(startDateString);
      }

      if (tournament.end_date !== null && tournament.end_date !== undefined) {
        var endDateString = tournament.end_date + 'Z';
        endDate = new Date(endDateString);
      }

      return (
        <div
          className={styles.CardContainer}
          style={{
            padding: fullSize ? '' : '0 1rem',
            width: fullSize ? '50%' : '100%',
            maxWidth: fullSize ? '50%' : '100%',
          }}
        >
          <div
            className={[styles.EventItem, tourCodeStyle].join(' ')}
            style={{
              flexFlow: fullSize ? 'row nowrap' : 'column nowrap',
            }}
          >
            <img className={styles.TourLogo} src={`${tournamentImageSource}`} alt="Logo for tournament" />
            <div style={{ maxWidth: '100%' }}>
              {tourTag}
              <p className={styles.EventDates}>
                {formatTournamentDateRange({ sameMonth, startDate, endDate }, true)}
                &nbsp;|&nbsp;{tournament.courses[0].city}, {tournament.courses[0].state}
              </p>
              <p className={styles.EventName}>{tournament.Tournament_Name}</p>
              {showPlayerInfo ? (
                <div>
                  <p
                    style={{
                      color: isCommitted(commitmentsInfo?.commitmentCode) ? 'green' : 'red',
                    }}
                  >
                    {commitmentsInfo?.positionInField
                      ? commitmentsInfo.positionInField + '/' + commitmentsInfo.fieldSizeScheduled
                      : '/' + (commitmentsInfo?.fieldSizeScheduled || '')}{' '}
                    {isCommitted(commitmentsInfo.commitmentCode) ? 'Committed' : 'Not Committed'}
                    {'  '}
                    {commitmentsInfo?.commitmentNumber ? '#' + commitmentsInfo?.commitmentNumber : ''}
                  </p>
                  {isCommitted(commitmentsInfo?.commitmentCode) && teeTime !== null && teeTime !== undefined ? (
                    <div>
                      <strong>Tee Time for Round {currentRound}</strong>
                      <br />
                      {new Intl.DateTimeFormat('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        timeZone: 'UTC',
                      }).format(new Date(teeTimeDate))}{' '}
                      - Start at Hole #{teeTime.start_hole}
                      {showCourse ? courseName : null}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className={[styles.ContactInformation, 'contact-table'].join(' ')}>
                {showContactInfo ? Parser(contactInformation, { replace: (node) => replaceTournamentInfoPageLinks(node) }) : ''}
              </div>
              {hardcodedPdf.length > 0 && (
                <div className={styles.ContentContainer}>
                  <p>
                    <a href={hardcodedPdf}>Click here for tournament contact info and the virtual locker room</a>
                  </p>
                </div>
              )}
              <div className={styles.EventLinks}>
                {this.props.tournamentInfo !== undefined ? (
                  <div className={styles.EventLink}>
                    <Link to={tournamentInfoLink} tabIndex="0">
                      Tournament Information
                    </Link>
                    <i className="fas fa-chevron-right" />
                  </div>
                ) : (
                  ''
                )}

                {showFieldLink && (
                  <div className={styles.EventLink}>
                    <Link to={tournamentFieldPath} tabIndex="0">
                      Tournament Field
                    </Link>
                    <i className="fas fa-chevron-right" />
                  </div>
                )}

                {tourCodeLetter !== 'M' && tourCodeLetter !== 'C' ? (
                  <div className={styles.EventLink}>
                    <Link to={weatherReportLink} tabIndex="0" target="_blank">
                      Weather Report
                    </Link>
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                ) : null}

                {showLinks && tourCodeLetter !== 'M' && tourCodeLetter !== 'C' ? (
                  <div className={styles.EventLink}>
                    <Link to={holeLocationsLink} tabIndex="0">
                      Hole Locations
                    </Link>
                    <i className="fas fa-chevron-right"></i>
                  </div>
                ) : (
                  ''
                )}

                {showLinks && tourCodeLetter === 'R' ? (
                  <div className={styles.EventLink}>
                    <a href={owgrPointsLinke} tabIndex="0" target="_blank" rel="noreferrer">
                      WGR Points Available
                    </a>
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                ) : (
                  ''
                )}

                {showTeeTimes ? (
                  <div className={styles.EventLink}>
                    <Link to={fullTeeTimesLink} tabIndex="0">
                      Tee Times
                    </Link>
                    <i className="fas fa-chevron-right"></i>
                  </div>
                ) : (
                  ''
                )}
                {proAm1 && tourCodeLetter === 'S' ? (
                  <div className={styles.EventLink}>
                    <Link to={proAmTeeTimesLink} tabIndex="0" target="_blank">
                      Pro-Am 1 Tee Times
                    </Link>
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                ) : (
                  ''
                )}
                {proAm1 && tourCodeLetter !== 'S' ? (
                  <div className={styles.EventLink}>
                    <Link to={proAmTeeTimesLink} tabIndex="0" target="_blank">
                      Pro-Am Tee Times
                    </Link>
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                ) : (
                  ''
                )}
                {proAm2 && tourCodeLetter === 'S' ? (
                  <div className={styles.EventLink}>
                    <Link to={proAmTeeTimesLink2} tabIndex="0" target="_blank">
                      Pro-Am 2 Tee Times
                    </Link>
                    <i className="fas fa-external-link-alt"></i>
                  </div>
                ) : (
                  ''
                )}
                {showProamFieldLink && (
                  <div className={styles.EventLink}>
                    <Link to={tournamentProAmFieldLink} tabIndex="0">
                      Pro-Am Field
                    </Link>
                    <i className="fas fa-chevron-right" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const commitmentInfo = state.commitmentInfo;
  return { profile, commitmentInfo };
};

export default connect(mapStateToProps)(UpcomingEventCard);
