import React from 'react';
import styles from './ContentPage.module.css';
import ContentHeader from '../../Common/Headers/ContentHeader';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import SharepointApi from '../../../api/sharepointApi';
import NavigationRoles from '../../Common/Navigation/NavigationRoles.json';
import { connect } from 'react-redux';
import sanitize from 'sanitize-html';
import Loader from '../../Common/Loader/Loader';
import { withParamsAndNavigation } from '../../../util/routerHooks';
import { hardcodedData } from '../../hardcoded/data';
import { SHAREPOINT_BASE_URL } from '../../../url/service-url';

class ContentPage extends React.PureComponent {
  contentVariables = {};
  _isMounted = false;

  constructor(props) {
    super(props);

    const params = this.props.params;
    this.state = {
      isLoading: true,
      pageHasStaticData: hardcodedData.contentPageSectionsWithStaticData[params.tourId][params.sectionName][params.contentId],
      contentId: params.contentId,
      sectionName: params.sectionName,
      tourId: params.tourId,
      title: '',
      content: '',
      anchors: '',
    };

    this.getContentVariables();
    this.replaceTournamentInfoPageLinks = this.replaceTournamentInfoPageLinks.bind(this);
  }

  getContentVariables(tourId = undefined, contentId = undefined, sectionName = undefined) {
    const role = this.props.profile.type;
    const tour = tourId !== undefined ? tourId : this.state.tourId;
    const page = contentId !== undefined ? contentId : this.state.contentId;
    const section = sectionName !== undefined ? sectionName : this.state.sectionName;
    this.contentVariables = NavigationRoles[role].tours[tour][section]?.find((obj) => obj.id.toString() === page.toString());
    if (this.contentVariables === undefined) {
      this.props.navigate('/error');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.params.contentId !== prevProps.params.contentId ||
      this.props.params.sectionName !== prevProps.params.sectionName ||
      this.props.params.tourId !== prevProps.params.tourId
    ) {
      this.setState(
        {
          pageHasStaticData:
            hardcodedData.contentPageSectionsWithStaticData[this.props.params.tourId][this.props.params.sectionName][this.props.params.contentId],
          tourId: this.props.params.tourId,
          sectionName: this.props.params.sectionName,
          contentId: this.props.params.contentId,
        },
        () => {
          this.getContentVariables(this.props.params.tourId, this.props.params.contentId, this.props.params.sectionName);
          this.fetchNewData(this.contentVariables.props.contentIds);
        },
      );
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchNewData(this.contentVariables.props.contentIds);
  }

  findText(startNode) {
    let currentNode = startNode;
    while (currentNode) {
      if (currentNode.type === 'text') {
        return currentNode.data;
      }
      currentNode = currentNode.children[0];
    }
    return '';
  }

  replaceTournamentInfoPageLinks(domNode) {
    if (domNode.name === 'a' && domNode.attribs?.href != null && domNode.attribs.href.includes('sites/PGATOURLINKS/SitePages/TI')) {
      const split = domNode.attribs.href.split('/');
      const pageName = split[split.length - 1];
      const text = this.findText(domNode);
      return <Link to={`/tournament/${pageName}`}>{text}</Link>;
    }
    if (domNode.name === 'a' && domNode.attribs?.href != null && domNode.attribs.href.includes('sites/PGATOURLINKS')) {
      const split = domNode.attribs.href.substring(domNode.attribs.href.indexOf('sites/PGATOURLINKS/SiteAssets' + 1));
      const text = this.findText(domNode);
      return (
        <a href={`/${split}`} target="_blank" rel="noreferrer">
          {text}
        </a>
      );
    }
  }

  fetchNewData(contentIds) {
    this.setState({ isLoading: true, content: '' });
    let newContent = [];
    let newAnchors = [];

    const createContent = (index, data, id) => {
      if (data.error !== undefined) {
        return;
      }
      const htmlExists = data.value != null && data.value.length > 0 && data.value.findIndex((item) => item.innerHtml !== undefined) >= 0;
      if (htmlExists) {
        SharepointApi.getFile(SHAREPOINT_BASE_URL + id).then((response) => {
          const firstIndex = data.value.findIndex((item) => item.innerHtml !== undefined);
          const innerHtml = data.value[firstIndex].innerHtml;
          const contentPart = sanitize(innerHtml, {
            allowedAttributes: false,
          });
          const parsedContent = Parser(contentPart, {
            replace: this.replaceTournamentInfoPageLinks,
          });

          newContent[index] = (
            <React.Fragment key={`nc-${index}`}>
              <div className="page-title" id={`anchor${index}`}>
                {response.title}
              </div>
              <div className="content-section">{parsedContent}</div>
            </React.Fragment>
          );
          newAnchors[index] = (
            <div className="anchor-link" key={`na-${index}`}>
              <a tabIndex={0} href={`#anchor${index}`}>
                {response.title}
                <i className="fas fa-arrow-circle-down" />
              </a>
            </div>
          );
          this.setNewData(newContent, newAnchors);
        });
      } else if (data.value[0].data.type !== 'rte') {
        console.log('EMBEDDED PDF WEBPART FOUND: ' + data.value[0].data);
      }
    };

    // const generateHardcodedMarkup = (index, data) => {
    //   const contentPart = sanitize(data.innerHtml, {
    //     allowedAttributes: false,
    //   });
    //   const parsedContent = Parser(contentPart, {
    //     replace: this.replaceTournamentInfoPageLinks,
    //   });

    //   newContent[index] = (
    //     <React.Fragment key={`nc-${index}`}>
    //       <div className="page-title" id={`anchor${index}`}>
    //         {data.title}
    //       </div>
    //       <div className="content-section">{parsedContent}</div>
    //     </React.Fragment>
    //   );
    //   newAnchors[index] = (
    //     <div className="anchor-link" key={`na-${index}`}>
    //       <a tabIndex={0} href={`#anchor${index}`}>
    //         {data.title}
    //         <i className="fas fa-arrow-circle-down" />
    //       </a>
    //     </div>
    //   );
    //   this.setNewData(newContent, newAnchors);
    // };

    const fetchData = (id, index) => {
      //   if (this.state.pageHasStaticData) {
      //     console.log("USING HARDCODED DATA");
      //     if (hardcodedData.contentByPageID[id]) {
      //       generateHardcodedMarkup(index, hardcodedData.contentByPageID[id]);
      //     }
      //   } else {
      SharepointApi.getContent(id).then((data) => {
        createContent(index, data, id);
      });
      //   }
    };

    const fetchEmbeddedFile = (id, index) => {
      const tempId = id.split('/');
      const tempTitle = tempId[tempId.length - 1];
      const title = tempTitle.replace(/_/g, ' ');
      tempId.pop();
      id = tempId.join('/');
      SharepointApi.getContent(id).then((result) => {
        newContent[index] = (
          <React.Fragment key={`nc-${index}`}>
            <div className="page-title" id={`anchor${index}`}>
              {title}
            </div>
            <div className="content-section">
              <a href={result['@microsoft.graph.downloadUrl']}>View {title}</a>
            </div>
          </React.Fragment>
        );
      });
    };

    contentIds.forEach((contentIdItem, i) => {
      const contentType = contentIdItem.split('/');
      if (contentType[0] === 'pages') {
        fetchData(contentIdItem, i);
      } else {
        fetchEmbeddedFile(contentIdItem, i);
      }
    });
  }

  setNewData(newContent, newAnchors) {
    this.setState({
      isLoading: false,
      content: newContent.slice(),
      anchors: newAnchors.slice(),
    });
  }

  render() {
    const fullSize = !(this.props.globalStyles.isMobileSize || this.props.globalStyles.isTabletSize);
    const isUpcomingTournamentsPage = this.contentVariables.props.title === 'Upcoming Tournaments';
    const contentStyle = isUpcomingTournamentsPage ? [styles.PageContent, styles.UpcomingTournaments].join(' ') : styles.PageContent;
    return (
      <div style={{ minHeight: this.props.globalStyles.windowHeight }}>
        {this.state.isLoading ? (
          <Loader withWrapper />
        ) : (
          <div>
            <ContentHeader
              title={this.contentVariables.props.title}
              tour={this.state.tourId}
              isLogoOnly={this.contentVariables.props.isLogoOnly}
              logoFile={this.contentVariables.props.logoFile}
              globalStyles={this.props.globalStyles}
            />
            {this.contentVariables.props.contentIds.length > 1 && (
              <div className={styles.AnchorLinks} style={{ flexFlow: fullSize ? 'row nowrap' : 'column nowrap' }}>
                <div className={styles.AnchorLinksHeader}>Scroll to:</div>
                {this.state.anchors}
              </div>
            )}
            <div className={styles.ContentContainer} style={{ minHeight: this.props.globalStyles.windowHeight }}>
              <div className={contentStyle}> {this.state.content}</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  const tournamentPages = state.tournamentPages;
  return { profile, tournamentPages };
};

export default withParamsAndNavigation(connect(mapStateToProps)(ContentPage));
