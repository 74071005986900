import React from 'react';
import styles from './Alerts.module.css';
import Parser from 'html-react-parser';
import { replaceTournamentInfoPageLinks } from '../../../util/sharepoint';

const StyledAlert = (props) => {
  const alertMessage = props.alertContent;

  const alertMessageItem = Parser(alertMessage, { replace: (node) => replaceTournamentInfoPageLinks(node) });

  return (
    <div className={styles.AlertWindow}>
      <div className={styles.HornIcon}>
        <i className="fas fa-bullhorn" />
      </div>
      <div className={styles.AlertMessage}>{alertMessageItem}</div>
      <div className={styles.CloseButton} onClick={props.handler}>
        <i className="fas fa-times" />
      </div>
    </div>
  );
};

export default StyledAlert;
