import React from 'react';
import styles from './Contact.module.css';
import SharepointApi from '../../api/sharepointApi';
import parse from 'html-react-parser';
import Loader from '../Common/Loader/Loader';
import { replaceTournamentInfoPageLinks } from '../../util/sharepoint';

export default class HelpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    SharepointApi.getContent('pages/165bf828-9f46-46f4-91cc-265f4480103b').then((result) => {
      console.log(result);
      if (result !== undefined) {
        this.setState({
          isLoading: false,
          content: result.value[0].innerHtml,
        });
      }
    });
  }

  render() {
    const { isLoading, content } = this.state;

    if (isLoading) {
      return <Loader withWrapper />;
    }

    const errorDisplay = (
      <>
        <p>There was an issue retrieving the Player Technology Team members. Please try again later.</p>
        <p>
          If this problem persists, please contact <a href="mailto:playertechnology@pgatourhq.com">playertechnology@pgatourhq.com</a> or{' '}
          <a href="tel:18887429558">1-888-742-9558</a>.
        </p>
      </>
    );

    return (
      <div className={styles.PageContainer}>
        <div style={{ minHeight: this.props.globalStyles.windowHeight }}>
          <h1 className={styles.FormHeader}>Player Technology Team</h1>
          <div className={styles.Contacts}>
            {content ? parse(content, { replace: (node) => replaceTournamentInfoPageLinks(node) }) : errorDisplay}
          </div>
        </div>
      </div>
    );
  }
}
