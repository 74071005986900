import React from 'react';
import styles from './DashboardComponents.module.css';
import CardHeader from '../../../Common/Headers/CardHeader';
import parse from 'html-react-parser';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SharepointApi from '../../../../api/sharepointApi';
import { connect } from 'react-redux';
import { CODE_LOOKUP, getTabMap } from '../../../../util/tabMap';
import Loader from '../../../Common/Loader/Loader';
import { fetchSharePointData } from '../../../../util/sharepointUtils';
import { replaceTournamentInfoPageLinks } from '../../../../util/sharepoint';
import {
  CHAMP_TOUR_ANNOUNCEMENTS,
  KF_TOUR_ANNOUNCEMENTS,
  PGA_TOUR_ANNOUNCEMENTS,
  PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
} from '../../../../url/service-url';

class Announcements extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      announcements: {
        pgaTour: '',
        championsTour: '',
        kornFerryTour: '',
        pgaTourAmericas: '',
      },
      showAnnouncements: {
        pgaTour: false,
        championsTour: false,
        kornFerryTour: false,
        pgaTourAmericas: false,
      },
      tabMap: [],
      hardcodedPdf: '',
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const tabMap = getTabMap(this.props.profile.tourCodes);
    const tourCode = tabMap[0] && tabMap[0].tourCode;
    this.getAnnouncements(tourCode);

    if (this._isMounted) {
      this.setState({
        tabMap: tabMap,
      });
    }
  }

  getAnnouncements(tourCode) {
    SharepointApi.getAnnouncements(tourCode, this.props.profile.type).then((resp) => {
      if (this._isMounted && resp?.value !== undefined) {
        const announcements = resp.value.map((p) => p.innerHtml).join('');
        this.setState((prevState) => ({
          isLoading: false,
          announcements: {
            ...prevState.announcements,
            [CODE_LOOKUP[tourCode].tournamentName]: announcements,
          },
        }));
      } else {
        console.log('USING FALLBACK DATA FOR ANNOUNCEMENTS');
        const urls = {
          player: {
            R: PGA_TOUR_ANNOUNCEMENTS,
            S: CHAMP_TOUR_ANNOUNCEMENTS,
            H: KF_TOUR_ANNOUNCEMENTS,
            Y: PGA_TOUR_AMERICAS_ANNOUNCEMENTS,
          },
        };
        fetchSharePointData(`${urls.player[tourCode]}`).then((response) => {
          if (response) {
            this.setState((prevState) => ({
              isLoading: false,
              hardcodedPdf: response.webUrl,
            }));
          }
        });
      }
    });
  }

  handleTabChange(index, lastIndex) {
    if (index !== lastIndex) {
      this.getAnnouncements(this.state.tabMap[index].tourCode);
    }
  }

  render() {
    const { isLoading, showAnnouncements, announcements, tabMap, hardcodedPdf } = this.state;
    if (isLoading) {
      return (
        <div className={styles.SectionContainer}>
          <CardHeader title="Announcements" icon="address-card" globalStyles={this.props.globalStyles} />
          <Loader withWrapper wrapperStyle={{ textAlign: 'center' }} />
        </div>
      );
    } else if (hardcodedPdf.length > 0) {
      const tabs = tabMap.map((item, i) => (
        <Tab key={i}>
          <div className={item.className}>{item.tournament}</div>
        </Tab>
      ));
      const tabPanels = tabMap.map((item, i) => (
        <TabPanel className={styles.AnnouncementsContainer} key={i}>
          {isLoading ? (
            ' '
          ) : (
            <div className={styles.AnnouncementCard}>
              <p>
                <a href={hardcodedPdf}>Click here to view in PDF</a>
              </p>
            </div>
          )}
        </TabPanel>
      ));
      return (
        <div className={styles.SectionContainer}>
          <CardHeader title="Announcements" icon="address-card" globalStyles={this.props.globalStyles} />
          <Tabs onSelect={this.handleTabChange}>
            <TabList>{tabs}</TabList>
            {tabPanels}
          </Tabs>
        </div>
      );
    } else if (this.props.globalStyles.isMobileSize || this.props.globalStyles.isTabletSize) {
      const tabs = tabMap.map((item, i) => {
        let announcementItems = parse(announcements[item.tournamentName], { replace: (node) => replaceTournamentInfoPageLinks(node) });
        return (
          <div key={i} style={{ width: '100%' }}>
            <div
              onClick={() => this.expandOptions(item.tourCode)}
              className={
                showAnnouncements[item.tournamentName]
                  ? [styles.AnnouncementMenuParent, styles.AnnouncementMenuParent_Active].join(' ')
                  : styles.AnnouncementMenuParent
              }
            >
              {item.tournament}
              <i className={showAnnouncements[item.tournamentName] ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
            </div>
            {showAnnouncements[item.tournamentName] && <div className={styles.AnnouncementMenuContent}>{announcementItems}</div>}
          </div>
        );
      });

      return (
        <div className={styles.SectionContainer}>
          <CardHeader title="Announcements" icon="address-card" globalStyles={this.props.globalStyles} />
          <div className={styles.MobileSectionMenu}>{tabs}</div>
        </div>
      );
    } else {
      const tabs = tabMap.map((item, i) => (
        <Tab key={i}>
          <div className={item.className}>{item.tournament}</div>
        </Tab>
      ));
      const tabPanels = tabMap.map((item, i) => (
        <TabPanel className={styles.AnnouncementsContainer} key={i}>
          {isLoading ? (
            ' '
          ) : (
            <div className={styles.AnnouncementCard}>
              {parse(announcements[item.tournamentName], { replace: (node) => replaceTournamentInfoPageLinks(node) })}
            </div>
          )}
        </TabPanel>
      ));

      return (
        <div className={styles.SectionContainer}>
          <CardHeader title="Announcements" icon="address-card" globalStyles={this.props.globalStyles} />
          <Tabs onSelect={this.handleTabChange}>
            <TabList>{tabs}</TabList>
            {tabPanels}
          </Tabs>
        </div>
      );
    }
  }

  expandOptions(tourCode) {
    const { showAnnouncements, tabMap } = this.state;
    const { tournamentName } = CODE_LOOKUP[tourCode];
    const falseAnnouncements = {
      pgaTour: false,
      championsTour: false,
      kornFerryTour: false,
      pgaTourAmericas: false,
    };

    const index = tabMap.findIndex((tour) => tour.tourCode === tourCode);
    const newShowAnnouncements = {
      ...falseAnnouncements,
      [tournamentName]: !showAnnouncements[tournamentName],
    };

    if (this._isMounted) {
      this.handleTabChange(index);
      this.setState({
        showAnnouncements: newShowAnnouncements,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

const mapStateToProps = (state) => {
  const profile = state.profile;
  return { profile };
};

export default connect(mapStateToProps)(Announcements);
