import { COMMITMENT_URL } from '../url/service-url';
import fetchRetry from '../util/fetchRetry';

export default {
  //GET
  getPlayerTimings: (playerId, token) => {
    return fetchRetry(COMMITMENT_URL + `players/${playerId}/timings/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: player timings ${error}`);
      });
  },
  getPlayerCommitmentWorksheet: (playerId, tourCode, token) => {
    return fetchRetry(COMMITMENT_URL + `commitments/${playerId}/${tourCode}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get commitments ${error}`);
      });
  },
  getPlayerMobilePreferences: (playerId, token) => {
    return fetchRetry(COMMITMENT_URL + `players/mobilepreferences/${playerId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get commitments ${error}`);
      });
  },
  getMobileCarriers: (token) => {
    return fetchRetry(COMMITMENT_URL + `commitments/carriers/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get commitments ${error}`);
      });
  },
  getFieldReport: (tournamentId, token) => {
    return fetchRetry(COMMITMENT_URL + `tournaments/${tournamentId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get field report ${error}`);
      });
  },
  getProAmFieldReport: (tournamentId, token) => {
    return fetchRetry(COMMITMENT_URL + `tournaments/proam/${tournamentId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get proam field report ${error}`);
      });
  },
  getPlayerStrengthOfFieldReport: (playerId, token) => {
    return fetchRetry(COMMITMENT_URL + `strengthoffield/${playerId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get SOF ${error}`);
      });
  },
  getTeamField: (tournamentId, token) => {
    return fetchRetry(COMMITMENT_URL + `field/team/${tournamentId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get Team Field ${error}`);
      });
  },
  getTeamProAmField: (tournamentId, token) => {
    return fetchRetry(COMMITMENT_URL + `field/team/proam/${tournamentId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get Team ProAm Field ${error}`);
      });
  },
  getMemberList: (playerId, tournamentScheduleId, token) => {
    return fetchRetry(COMMITMENT_URL + `memberlist/${playerId}/${tournamentScheduleId}/${token}`)
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: get Member List ${error}`);
      });
  },

  //POST
  postPlayerCommitments: (data, token) => {
    return fetch(COMMITMENT_URL + `players/commitments`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: post player commitments ${error}`);
      });
  },
  postTeamCommitments: (data, token) => {
    return fetch(COMMITMENT_URL + `team/commitments`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: post team commitments ${error}`);
      });
  },
  addPlayerEmailAddress: (data, token) => {
    return fetch(COMMITMENT_URL + `players/mobilepreferences/addemail`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'Bearer ' + token,
      },
      body: JSON.stringify({ data, token }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: add email address ${error}`);
      });
  },
  addPlayerPhoneNumber: (data, token) => {
    return fetch(COMMITMENT_URL + `players/mobilepreferences/addphone`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'Bearer ' + token,
      },
      body: JSON.stringify({ data, token }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API: add phone number ${error}`);
      });
  },

  // DELETE
  deletePlayerEmailAddress: (id, token) => {
    return fetch(COMMITMENT_URL + `players/mobilepreferences/emailmessage/${id}/${token}`, {
      method: 'get',
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API: delete email ${error}`);
      });
  },
  deletePlayerPhoneNumber: (id, token) => {
    return fetch(COMMITMENT_URL + `players/mobilepreferences/phonemessage/${id}/${token}`, {
      method: 'get',
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API: delete phone number ${error}`);
      });
  },

  // PUT
  updatePlayerEmailAddress: (data, token) => {
    return fetch(`${COMMITMENT_URL}players/mobilepreferences/updateemail`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'Bearer ' + token,
      },
      body: JSON.stringify({ data, token }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: update email address ${error}`);
      });
  },
  updatePlayerPhoneNumber: (data, token) => {
    return fetch(`${COMMITMENT_URL}players/mobilepreferences/updatephone`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'Bearer ' + token,
      },
      body: JSON.stringify({ data, token }),
    })
      .then((res) => res.json())
      .catch((error) => {
        console.log(`Error in Commitment API call: update phone number ${error}`);
      });
  },
};
